import * as API from '@/api/index'


export default {
    // 查询话术
    getProduct:parme=>{
        return API.POST("api/artSpeak/list",parme);
    },
    // 新增话术
    createProduct:parme=>{
        return API.POST("api/artSpeak/create",parme);
    },
    // 编辑话术
    updateProduct:parme=>{
        return API.POST("api/artSpeak/update",parme);
    },
    // 分类
    artSpeakCategory:parme=>{
        return API.POST("api/artSpeakCategory/valid",parme);
    },
    // 删除话术
    
    artSpeakDelete:parme=>{
        return API.POST("api/artSpeak/delete",parme);
    },
}
